import { create } from "zustand";
import firebase from "firebase";
import { interactiveType } from "./types";

const timestamp = firebase.firestore.FieldValue.serverTimestamp();
const useInteractiveStore = create((set, get) => ({
  interactives: {},
  profileInteractions: {},
  currentInteractive: null,
  interactiveStatus: "",
  interactiveLoading: false,
  interactiveError: "",

  // Get Channel's Interactivity
  getChannelInteractives: ({ channelId }) => {
    set({
      interactiveStatus: interactiveType.GETCHANNELINTERACTIVITIES_LOADING,
    });
    firebase
      .firestore()
      .collection("channels")
      .doc(channelId)
      .collection("interactives")
      .orderBy("createdAt", "desc")
      .onSnapshot((snaps) => {
        let results = snaps?.docs.map((doc) => doc.data());
        set({
          interactives: {
            ...get().interactives,
            [`${channelId}`]: results,
          },
          interactiveStatus: interactiveType.GETCHANNELINTERACTIVITIES_SUCCESS,
        });
      });
  },

  // Create Interactivity
  createInteractivity: ({ channelId, data }) => {
    set({ interactiveStatus: interactiveType.CREATEINTERACTIVITY_LOADING });
    const uid = firebase.auth().currentUser().uid;
    let ref = firebase
      .firestore()
      .collection("channels")
      .doc(channelId)
      .collection("interactives")
      .doc();
    ref
      .set({
        channelId,
        interactiveId: ref.id,
        ...data,
        authorId: uid,
        createdAt: timestamp,
        updatedAt: timestamp,
      })
      .then(() => {
        set({ interactiveStatus: interactiveType.CREATEINTERACTIVITY_SUCCESS });
      })
      .catch((error) => {
        set({
          interactiveStatus: interactiveType.CREATEINTERACTIVITY_FAIL,
          interactiveError: error?.message,
        });
      });
  },

  // Update Interactivity
  updateInteractivity: ({ channelId, interactiveId, data }) => {
    set({ interactiveStatus: interactiveType.UPDATEINTERACTIVITY_LOADING });
    firebase
      .firestore()
      .collection("channels")
      .doc(channelId)
      .collection("interactives")
      .doc(interactiveId)
      .update({
        ...data,
        updatedAt: timestamp,
      })
      .then(() => {
        set({ interactiveStatus: interactiveType.UPDATEINTERACTIVITY_SUCCESS });
      })
      .catch((error) => {
        set({
          interactiveStatus: interactiveType.UPDATEINTERACTIVITY_FAIL,
          interactiveError: error?.message,
        });
      });
  },

  // Delete Interactivity
  deleteInteractivity: ({ channelId, interactiveId }) => {
    set({ interactiveStatus: interactiveType.DELETEINTERACTIVITY_LOADING });
    firebase
      .firestore()
      .collection("channels")
      .doc(channelId)
      .collection("interactives")
      .doc(interactiveId)
      .delete()
      .then(() => {
        set({ interactiveStatus: interactiveType.DELETEINTERACTIVITY_SUCCESS });
      })
      .catch((error) => {
        set({
          interactiveStatus: interactiveType.DELETEINTERACTIVITY_FAIL,
          interactiveError: error?.message,
        });
      });
  },

  // Get User Profile Interactions
  getUserProfileInteractions: ({
    setUnsubscribe,
    channelId,
    interactiveId,
  }) => {
    const unsubscribe = firebase
      .firestore()
      .collectionGroup("profileInteractions")
      .where("channelId", "==", channelId)
      .where("interactiveId", "==", interactiveId)
      .onSnapshot((snaps) => {
        let results = snaps?.docs.map((doc) => doc.data());
        set({
          profileInteractions: {
            ...get().profileInteractions,
            [`${interactiveId}`]: results,
          },
          interactiveStatus: interactiveType.GETPROFILEINTERACTIONS_SUCCESS,
        });
      });
    setUnsubscribe(unsubscribe);
  },

  resetProfileInteractions: ({ channelId, interactiveId }) => {
    // console.log("starting reset");

    firebase.firestore().collection('channels').doc(channelId).collection('interactives').doc(interactiveId).get()
    .then((snap) => {
      let toCopy = snap.data();

      let ref = firebase
          .firestore()
          .collection("channels")
          .doc(channelId)
          .collection("interactives")
          .doc();
        ref
          .set({
            ...toCopy,
            channelId,
            interactiveId: ref.id,
            createdAt: timestamp,
            updatedAt: timestamp,
            status: 'active',
          })
          .then(() => {
            set({
              interactiveStatus: interactiveType.CREATEINTERACTIVITY_SUCCESS,
            });

            //delete old interactivity
            firebase
              .firestore()
              .collection("channels")
              .doc(channelId)
              .collection("interactives")
              .doc(interactiveId)
              .delete()
              .then(() => {
                set({
                  interactiveStatus: interactiveType.DELETEINTERACTIVITY_SUCCESS,
                });
              })
            })
    })

    firebase
      .firestore()
      .collectionGroup("profileInteractions")
      .where("channelId", "==", channelId)
      .where("interactiveId", "==", interactiveId)
      .get()
      // .then((snaps) => {
      //   let results = snaps?.docs.map((doc) => doc.data());

      //   let result = results
      //     .filter((doc) => doc.data().interactivityId === interactiveId)
      //     .map((doc) => doc.data());

      //   console.log(results,result);

      //   //create new interactivity with same data as the reseted one
      //   set({ interactiveStatus: interactiveType.CREATEINTERACTIVITY_LOADING });
      //   let ref = firebase
      //     .firestore()
      //     .collection("channels")
      //     .doc(channelId)
      //     .collection("interactives")
      //     .doc();
      //   ref
      //     .set({
      //       ...result,
      //       channelId,
      //       interactiveId: ref.id,
      //       createdAt: timestamp,
      //       updatedAt: timestamp,
      //     })
      //     .then(() => {
      //       set({
      //         interactiveStatus: interactiveType.CREATEINTERACTIVITY_SUCCESS,
      //       });
      //     })
      //     .catch((error) => {
      //       set({
      //         interactiveStatus: interactiveType.CREATEINTERACTIVITY_FAIL,
      //         interactiveError: error?.message,
      //       });
      //     });

      //   //reset old interactivity
      //   console.log(`to delete - ${JSON.stringify(results)}`);
      //   // results.forEach((item) => {
      //   //   firebase.firestore().collection('users').doc(item.uid)
      //   //   .collection('profiles').doc(item.profileId)
      //   //   .collection('profileInteractions')
      //   //   .doc(`${channelId}-${interactiveId}`)
      //   //   .delete();
      //   // })

      //   //delete old interactivity
      //   set({ interactiveStatus: interactiveType.DELETEINTERACTIVITY_LOADING });
      //   firebase
      //     .firestore()
      //     .collection("channels")
      //     .doc(channelId)
      //     .collection("interactives")
      //     .doc(interactiveId)
      //     .delete()
      //     .then(() => {
      //       set({
      //         interactiveStatus: interactiveType.DELETEINTERACTIVITY_SUCCESS,
      //       });
      //     })
      //     .catch((error) => {
      //       set({
      //         interactiveStatus: interactiveType.DELETEINTERACTIVITY_FAIL,
      //         interactiveError: error?.message,
      //       });
      //     });
      // });
  },
  // resetProfileInteractions: ({ channelId, interactiveId }) => {
  //   console.log('starting reset')
  //   firebase.firestore().collectionGroup('profileInteractions')
  //   .where('channelId', '==', channelId)
  //   .where('interactiveId', '==', interactiveId)
  //   .get()
  //   .then((snaps) => {
  //     let results = snaps?.docs.map(doc => doc.data());
  //     console.log(`to delete - ${JSON.stringify(results)}`)
  //     results.forEach((item) => {
  //       firebase.firestore().collection('users').doc(item.uid)
  //       .collection('profiles').doc(item.profileId)
  //       .collection('profileInteractions')
  //       .doc(`${channelId}-${interactiveId}`)
  //       .delete();
  //     })
  //   })
  // },
}));

export default useInteractiveStore;
