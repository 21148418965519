import {create} from 'zustand';
import firebase from 'firebase';
import { resourceType } from './types';

const timestamp = firebase.firestore.FieldValue.serverTimestamp();

const useResourceStore = create((set, get) => ({
    resources: [],
    resourcesData: {}, // cache
    currentResource: null,
    episodesData: {}, 
    currentEpisode: null,
    resourceStatus: '',
    resourceLoading: false,
    resourceError: '',

    getResources: () => {
      set({ resourceStatus: resourceType.GETRESOURCES_LOADING });
      firebase.firestore().collection('resources')
      .get()
      .then((snaps) => {
        let results = snaps.docs.map(doc => doc.data());
        // let newData = [];
        // results.forEach((item) => {
        //   newData[item.resourceId] = item;
        // });

        set({ 
          resourceStatus: resourceType.GETRESOURCES_SUCCESS,
          resources: results,
          // resourcesData: {
          //   ...get().resourcesData,
          //   ...newData,
          // },
        });
      })
      .catch((error) => {
        set({ 
          resourceStatus: resourceType.GETRESOURCES_FAIL,
          resourceError: error.message,
        });
      });
    },

    getResource: ({ resourceId }) => {
      set({ resourceStatus: resourceType.GETRESOURCE_LOADING });
      firebase.firestore().collection('resources')
      .doc(resourceId)
      .get()
      .then((snap) => {
        const result= snap.data()
        // console.log(result);
        let newData = {
          ...get().resourcesData,
          [resourceId]: result,
        }
        set({ 
          resourceStatus: resourceType.GETRESOURCE_SUCCESS,
          currentResource: result,
          resourcesData: newData,
        });
        // set({ currentResource: null, });
      })
      .catch((error) => {
        set({ 
          resourceStatus: resourceType.GETRESOURCE_FAIL,
          resourceError: error.message,
        });
      });
    },

    createResource: ({ resource }) => {
      const uid = firebase.auth().currentUser?.uid;
      set({ resourceStatus: resourceType.CREATERESOURCE_LOADING });
      let ref = firebase.firestore().collection('resources').doc();
      ref.set({
        ...resource,
        resourceId: ref.id,
        authorId: uid,
        createdAt: timestamp,
        updatedAt: timestamp,
      })
      .then(() => {
        set({  resourceStatus: resourceType.CREATERESOURCE_SUCCESS, });
      })
      .catch((error) => {
        set({ 
          resourceStatus: resourceType.CREATERESOURCE_FAIL,
          resourceError: error.message,
        });
      });
    },

    updateResource: ({ resource }) => {
      set({ resourceStatus: resourceType.UPDATERESOURCE_LOADING });
      firebase.firestore().collection('resources').doc(resource.resourceId)
      .update({
        ...resource,
        updatedAt: timestamp,
      })
      .then(() => {
        set({ resourceStatus: resourceType.UPDATERESOURCE_SUCCESS });
        set({ resourceStatus: resourceType.IDLE });
      })
      .catch((error) => {
        set({ 
          resourceStatus: resourceType.UPDATERESOURCE_FAIL,
          resourceError: error.message,
        });
      });
    },

    deleteResource: ({ resourceId }) => {
      set({ resourceStatus: resourceType.DELETERESOURCE_LOADING });
      firebase.firestore().collection('resources').doc(resourceId)
      .delete()
      .then(() => {
        console.log(`delete resource success`);
        set({ resourceStatus: resourceType.DELETERESOURCE_SUCCESS });
      })
      .catch((error) => {
        set({ 
          resourceStatus: resourceType.DELETERESOURCE_FAIL,
          resourceError: error.message,
        });
      });
    },

    // Episodes
    getEpisodes: ({ resourceId, }) => {
      set({ resourceStatus: resourceType.GETEPISODES_LOADING });
      firebase.firestore().collection('resources').doc(resourceId)
      .collection('episodes')
      .get()
      .then((snaps) => {
        let results = snaps.docs.map(doc => doc.data());
        set({ 
          resourceStatus: resourceType.GETEPISODES_SUCCESS,
          episodesData: {
            ...get().episodesData,
            [`${resourceId}`]: results,
          },
        });
      })
    },
    getEpisode: ({ resourceId, episodeId, }) => {
      set({ resourceStatus: resourceType.GETEPISODE_LOADING });
      firebase.firestore().collection('resources').doc(resourceId)
      .collection('episodes').doc(episodeId)
      .get()
      .then((snap) => {
        set({ 
          resourceStatus: resourceType.GETEPISODE_SUCCESS,
          currentEpisode: snap.data(),
        });
        set({ currentResource: null, });
      })
      .catch((error) => {
        set({ 
          resourceStatus: resourceType.GETEPISODE_FAIL,
          resourceError: error.message,
        });
      });
    },
    createEpisode: ({ resourceId, episode }) => {
      const uid = firebase.auth().currentUser?.uid;
      set({ resourceStatus: resourceType.CREATEEPISODE_LOADING });
      let ref = firebase.firestore().collection('resources').doc(resourceId).collection('episodes').doc();
      ref.set({
        ...episode,
        episodeId: ref.id,
        resourceId,
        authorId: uid,
        createdAt: timestamp,
        updatedAt: timestamp,
      })
      .then(() => {
        set({ resourceStatus: resourceType.CREATEEPISODE_SUCCESS });
      })
      .catch((error) => {
        set({ 
          resourceStatus: resourceType.CREATEEPISODE_FAIL, 
          resourceError: error.message,
        });
      });
    },

    updateEpisode: ({ episode }) => {
      set({ resourceStatus: resourceType.UPDATEEPISODE_LOADING });
      firebase.firestore().collection('resources').doc(episode.resourceId)
      .collection('episodes').doc(episode.episodeId)
      .update({
        ...episode,
        updatedAt: timestamp,
      })
      .then(() => {
        set({ resourceStatus: resourceType.UPDATEEPISODE_SUCCESS });
        set({ resourceStatus: resourceType.IDLE });
      })
      .catch((error) => {
        set({ 
          resourceStatus: resourceType.UPDATEEPISODE_SUCCESS,
          resourceError: error.message,
        });
      });
    },

    deleteEpisode: ({ resourceId, episodeId }) => {
      // console.log(` resource id = ${resourceId} || episode id = ${episodeId}`)
      set({ resourceStatus: resourceType.DELETEEPISODE_LOADING });
      // Update the resource first to remove from seasons
      firebase.firestore().collection('resources').doc(resourceId)
      .get()
      .then((snap) => {
        let resource = snap.data();
        let newSeasons = [...resource?.seasons || []];

        // console.log(JSON.stringify(newSeasons));
        
        newSeasons?.forEach((seasonItem, seasonIndex) => {
          if (seasonItem?.episodes?.some(x => x === episodeId)) {
            const indexToDelete = seasonItem?.episodes?.findIndex(x => x === episodeId);
            let newSeasonEpisodes = [...newSeasons[seasonIndex]?.episodes];
            newSeasonEpisodes.splice(indexToDelete, 1);
            newSeasons[seasonIndex] = {
              ...newSeasons[seasonIndex],
              episodes: newSeasonEpisodes,
            };
          }
        });

        // Update the Resource
        get().updateResource({
          resource: {
            resourceId,
            seasons: newSeasons,
          }
        });

        // Now it's safe to Delete the Episode
        firebase.firestore().collection('resources').doc(resourceId)
        .collection('episodes').doc(episodeId)
        .delete()
        .then(() => {
          console.log('delete success')
          set({ resourceStatus: resourceType.DELETEEPISODE_SUCCESS });
        })
        .catch((error) => {
          console.log('error delete ' + error.message)
          set({ 
            resourceStatus: resourceType.DELETEEPISODE_SUCCESS,
            resourceError: error.message,
          });
        });

      })
      .catch((error) => {
          console.log('error delete ' + error.message)
        set({ 
          resourceStatus: resourceType.DELETEEPISODE_SUCCESS,
          resourceError: error.message,
        });
      });
    },

  }),
)
  
export default useResourceStore