import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CContainer,
  CSwitch,
  CSelect,
  CCardFooter,
  CInputCheckbox,
  CInput,
  CLabel,
  CFormGroup,
} from "@coreui/react";
import moment from "moment";
import CIcon from "@coreui/icons-react";
import useVodInteractiveStore from "src/stores/VodInteractive";
import { interactiveType, vodInteractiveType } from "src/stores/types";
import {
  Timeline,
  TimelineEngine,
  TimelineState,
  TimelineAction,
} from "@xzdarcy/react-timeline-editor";
import PopupControl from "./PopupControl";

import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import useResourceStore from "src/stores/Resource";

const mockData = [
  {
    id: "1",
    actions: [
      {
        id: "action1",
        authorId: "author1",
        resourceId: "resource1",
        interactiveId: "interactive1",
        referenceId: "",
        status: "active",
        style: "simple",
        title: "Question",
        type: "poll",
        createdAt: "timestamp",
        updatedAt: "timestamp",
        actionType: "", //seek/episode for navigation only
        seekTime: 5, //for navigation
        episodeId: "episode1", //for navigation
        currentQuestion: 0, //for quiz
        questions: [
          {
            choicesAnswer: 1,
            imageUrl: "url",
            question: "question1",
            questionId: "questionId1",
            choices: [
              {
                fullImage: "url1",
                iconUrl: "icurl1",
                imageUrl: "imgUrl1",
                linkUrl: "lnUrl1",
                optId: "optId1",
                text: "choice text",
              },
            ],
          },
        ],
        choices: [
          {
            fullImage: "url",
            iconUrl: "iconUrl",
            imageUrl: "imageUrl",
            linkUrl: "linkUrl",
            optId: "optId1",
            text: "Red",
          },
          {
            fullImage: "url",
            iconUrl: "iconUrl",
            imageUrl: "imageUrl",
            linkUrl: "linkUrl",
            optId: "optId1",
            text: "Blue",
          },
        ],
        start: 1,
        end: 2,
        effectId: "effect1",
        selected: false,
        bottom: 50,
        left: 50,
        name: "Question",
        question: "What's your favorite color?",
        // choices: ["Red", "Blue", "Green"],
        data: {
          src: "/lottie/lottie2/data.json",
          name: "工作",
        },
      },
    ],
  },
];

const mockEffect = {
  effect1: {
    id: "effect1",
    name: "播放动画",
    source: {
      enter: ({ action, time }) => {
        const src = action.data.src;
        PopupControl.enter({
          id: src,
          src,
          title: action.title,
          type: action.type,
          questions: action.questions,
          options: action.options,
          bottom: action.bottom,
          left: action.left,
          startTime: action.start,
          endTime: action.end,
          time,
        });
      },
      update: ({ action, time }) => {
        const src = action.data.src;
        PopupControl.update({
          id: src,
          src,
          title: action.title,
          type: action.type,
          questions: action.questions,
          options: action.options,
          choices: action.questions[0]?.choices,
          bottom: action.bottom,
          left: action.left,
          startTime: action.start,
          endTime: action.end,
          time,
        });
      },
      leave: ({ action, time }) => {
        // const src = action.data.src;
        PopupControl.leave({
          // id: src,
          startTime: action.start,
          endTime: action.end,
          time,
        });
      },
    },
  },
};

const engine = new TimelineEngine();

let userProfileSubscriber = () => {};

const VodInteractivity = ({ match }) => {
  const {
    // interactivityResources,
    interactivities,
    interactives,
    profileInteractions,
    interactiveStatus,
    interactiveError,
    getResourceInteractives,
    updateInteractivity,
    deleteInteractivity,
    // getUserProfileInteractions,
    resetProfileInteractions,
  } = useVodInteractiveStore();

  const {
    resourceStatus,
    resourcesData,
    currentResource,
    episodesData,
    getResource,
    getEpisodes,
  } = useResourceStore();

  const history = useHistory();
  const [darkMode, setDarkMode] = useState(true);
  //const usersData = useSelector(state => state.usersData)
  const [modalAlert, setModalAlert] = useState(null);
  // const [interactives, setInteractives] = useState([]);
  const [mockDataState, setMockDataState] = useState([]);
  const [mockEffectState, setMockEffectState] = useState({});

  const [autoScroll, setAutoScroll] = useState(true);
  const [dragLine, setDragLine] = useState(true);
  const [gridSnip, setGridSnip] = useState(true);

  const [startLeft, setStartLeft] = useState(20);
  const [scale, setScale] = useState(1);
  const [scaleWidth, setScaleWidth] = useState(160);
  const [scaleSplitCount, setScaleSplitCount] = useState(10);

  // const [isPlaying, setIsPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const [videoVolume, setVideoVolume] = useState(100);
  const [videoUrl, setVideoUrl] = useState("");

  // const idRef = useRef(0);
  const domRef = useRef();

  const timelineState = useRef();
  // const playerPanel = useRef();
  const autoScrollWhenPlay = useRef(true);

  // const [newDeltaPositionState, setNewDeltaPositionState] = useState()

  // var newDeltaPositionRef = useRef();
  var destinationRowIndexRef = useRef();

  // const [interactives, setInteractives] = useState([]);
  const [currentActive, setCurrentActive] = useState(null);
  const [currentActiveInteractions, setCurrentActiveInteractions] =
    useState(null);

  useEffect(() => {
    const resourceInteractives = interactives?.[match?.params?.id] || [];
    if (resourceInteractives.some((x) => x.status === "active")) {
      setCurrentActive(resourceInteractives.find((x) => x.status === "active"));
    } else {
      setCurrentActive(null);
      userProfileSubscriber();
    }
  }, [interactives]);

  const getInteractives = () => {
    if (match.params.id) {
      getResourceInteractives({ resourceId: match?.params?.id });
    }
  };

  useEffect(() => {
    getResource({ resourceId: match.params.id });
    
    console.log(
      `match.params.id`,
      match.params.id
    );
  }, []);
  
  useEffect(() => {
    if (resourcesData[match?.params?.id]?.type==="film") {
      getResource({ resourceId: match.params.id });
    }else{
      getEpisodes({ resourceId: resourcesData[match?.params?.id]?.resourceId });
    }
  }, []);


  useEffect(() => {
    setVideoUrl(resourcesData[match?.params?.id]?.url);
    console.log(
      `videoUrl`,
      videoUrl
    );
  }, [resourcesData]);

  // useEffect(() => {
  //   if (currentResource) {
  //     getEpisodes({ resourceId: currentResource?.resourceId });
  //   }
  // }, [currentResource]);

  //draggable states
  // const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 });
  // const [activeDrags, setActiveDrags] = useState(0);
  // Determine the number of rows based on your data (mockDataState or similar)
  // const numRows = mockDataState.length;

  const [deltaPositions, setDeltaPositions] = useState(
    Array(mockDataState.length).fill({ x: 0, y: 0 })
  );
  const [activeDrags, setActiveDrags] = useState([]);

  const [mouseDown, setMouseDown] = useState(false);

  var mouseDownRef = useRef();

  const handleDrag = (row) => (e, ui) => {
    // Clone the deltaPositions array
    const newDeltaPositions = [...deltaPositions];

    // Update the position for the current row
    newDeltaPositions[row.id] = {
      x: newDeltaPositions[row.id].x + ui.deltaX,
      y: newDeltaPositions[row.id].y + ui.deltaY,
    };

    setDeltaPositions(newDeltaPositions);

    // Clone the mockDataState
    const updatedMockData = [...mockDataState];

    setTimeout(() => {
      // Calculate the row height
      const rowHeight = 32;

      // Calculate the destination row position based on the updated deltaPosition
      const dragRow = Math.floor(
        (row.id * rowHeight + newDeltaPositions[row.id].y) / rowHeight
      );
      // const dragRow = Number(row.id) + newDeltaPositions[row.id].y / rowHeight;

      console.log(` dragRow `, dragRow);
      console.log(
        ` newDeltaPositions[row.id].y `,
        Math.abs(newDeltaPositions[row.id].y)
      );

      // Update the deltaPositions with the new position

      // Find the source and destination rows
      const sourceRowIndex = mockDataState.findIndex((r) => r.id === row.id);
      const destinationRowIndex = dragRow;
      destinationRowIndexRef.current = dragRow;

      // Find the actions to swap
      const sourceRowActions = updatedMockData[sourceRowIndex].actions;
      const destinationRowActions =
        updatedMockData[destinationRowIndex].actions;

      // Swap actions between source and destination rows
      updatedMockData[sourceRowIndex].actions = destinationRowActions;
      updatedMockData[destinationRowIndex].actions = sourceRowActions;
      // Update the state with the updated mockDataState
    }, 800);

    console.log(`handleDrag mouseDownRef.current`, mouseDownRef.current);
    //firebase update
    const draggedInteractive = interactives[match.params.id]?.filter(
      (interactive) => interactive?.id === row?.id
    );
    const updatedDrag = { start: row?.start, end: row?.end };

    // updateInteractive(draggedInteractive, updatedDrag);
    setTimeout(() => {
      if (mouseDownRef.current === true) {
        setMockDataState(updatedMockData);
        newDeltaPositions[row.id].y = 0;

        // updateInteractive(draggedInteractive, interactives?.actions[0]);
      }
    }, 950);
  };

  const dragHandlers = (row) => ({
    onStart: () => {
      // console.log(`onStart working row`,row);
      setActiveDrags([...activeDrags, row.id]);

      setMouseDown(false);
      mouseDownRef.current = false;
    },
    onStop: () => {
      setActiveDrags(activeDrags.filter((dragIndex) => dragIndex !== row.id));
      console.log(`onStop working row.id=`, row.id);
      console.log(`onStop working activeDrags=`, activeDrags);
      console.log(
        `onStop destinationRowIndexRef.current=`,
        destinationRowIndexRef.current
      );

      mouseDownRef.current = true;

      console.log(`onStop mouseDown`, mouseDownRef.current);

     
    },
    onMouseDown: () => {
      setActiveDrags([...activeDrags, row.id]);
      // const draggedInteractive=interactives[match.params.id].filter((interactive)=>interactive.id===row.id);
      // const updatedDrag = {start:row.start ,end: row.end}
      // updateInteractive(draggedInteractive,updatedDrag)
    },
    onDrop: (row) => () => {
      setActiveDrags(activeDrags.filter((dragIndex) => dragIndex !== row));
      // console.log(`onDrop`, row);
      if (
        document
          .getElementById(`drop-target-${row}`)
          .classList.contains("drop-target")
      ) {
        alert("Dropped!");
        document
          .getElementById(`drop-target-${row}`)
          .classList.remove("hovered");
      }
      console.log(`onDrop`);
    },
    onDropAreaMouseEnter: (row) => () => {
      if (activeDrags.includes(row)) {
        document.getElementById(`drop-target-${row}`).classList.add("hovered");
      }
    },
    onDropAreaMouseLeave: (row) => () => {
      document.getElementById(`drop-target-${row}`).classList.remove("hovered");
    },
  });

  // Add a state to track the 'y' values
  const [yValues, setYValues] = useState([]);

  // Use an effect to update the 'y' values whenever deltaPositions changes
  useEffect(() => {
    // console.log(`deltaPositions`, deltaPositions);
    const newYValues = deltaPositions.map((position) => position.y);
    setYValues(newYValues);

    // console.log("yValues:", newYValues);
  }, [deltaPositions]);

  // useEffect(() => {
  //   console.log(`mockDataState`, mockDataState);
  //   // console.log(engine);
  //   // updateInteractive()
  //   // SetVolume(100);
  // }, [mockDataState, mockEffectState]);

  useEffect(() => {
    getInteractives();
  }, []);

  useEffect(() => {
    // if(interactiveStatus===vodInteractiveType.DELETEINTERACTIVITY_FAIL){
    if (
      interactiveStatus === vodInteractiveType.UPDATEVODINTERACTIVITY_SUCCESS
    ) {
      getInteractives();
    }
    setMockEffectState(mockEffect);
  }, [interactiveStatus]);

  useEffect(() => {
    const filteredInteractives = interactivities?.filter(
      (interactive) => interactive.actions[0].status === "active"
    );
    if (filteredInteractives) {
      // console.log(`filteredInteractives`, filteredInteractives);
      setMockDataState(filteredInteractives);
    }
    if (interactivities) {
      // setMockDataState(interactivities);
      // console.log(`interactivities`, interactivities);
    } else {
      setMockDataState(mockData);
    }
  }, [interactivities]);

  useEffect(() => {
    if (interactiveStatus === vodInteractiveType.DELETEVODINTERACTIVITY_FAIL) {
      setModalAlert({
        title: "Unable to Delete",
        message: interactiveError,
        color: "danger",
        buttons: [{ title: "OK", onClick: () => setModalAlert(null) }],
      });
    }
  }, [interactiveStatus]);

  // useEffect(() => {
  //   if (currentActive) {
  //     getUserProfileInteractions({
  //       setUnsubscribe: (func) => (userProfileSubscriber = func),
  //       resourceId: currentActive?.resourceId,
  //       interactiveId: currentActive?.interactiveId,
  //     });
  //   } else {
  //     setCurrentActive(null);
  //     userProfileSubscriber();
  //   }
  // }, [currentActive]);

  useEffect(() => {
    if (profileInteractions?.[currentActive?.interactiveId]) {
      const resourceInteractions =
        profileInteractions?.[currentActive?.interactiveId];
      let opts = {};
      let total = 0;
      if (currentActive?.type === "quiz") {
        // For QUIZ
        const questionInteractions = {};
        currentActive?.questions?.forEach((qItem) => {
          let qOpts = {};
          let qTotal = 0;
          qItem?.choices?.forEach((item) => {
            const newCounter =
              0 +
              resourceInteractions?.filter(
                (obj) =>
                  obj[`question-${qItem?.questionId}`]?.selected === item?.optId
              ).length;
            qOpts[`${item.optId}`] = newCounter;
            qTotal += newCounter;
          });
          qOpts["total"] = qTotal;
          questionInteractions[`${qItem.questionId}`] = qOpts;
        });
        setCurrentActiveInteractions(questionInteractions);
      } else {
        // For POLLS
        currentActive?.choices?.forEach((item) => {
          const newCounter =
            0 +
            resourceInteractions?.filter((obj) => obj.selected === item.optId)
              .length;
          opts[`${item.optId}`] = newCounter;
          total += newCounter;
        });
        opts["total"] = total;
        setCurrentActiveInteractions(opts);
      }
    }
  }, [profileInteractions, currentActive]);

  useEffect(() => {
    let video = document.getElementById("myVideo");
    // console.log(`video.volume=`, video.volume);

    if (!timelineState.current) return;
    const engine = timelineState.current;

    engine.listener.on("afterSetTime", ({ time }) => {
      setTime(time);
      if(video){
        video.currentTime = time;
      }
      // console.log(`duration`,video.duration);
    });
    engine.listener.on("setTimeByTick", ({ time }) => {
      setTime(time);

      if (autoScrollWhenPlay.current) {
        const autoScrollFrom = 500;
        const left = time * (scaleWidth / scale) + startLeft - autoScrollFrom;
        timelineState.current.setScrollLeft(left);
      }
    });

    //to override autoEnd
    // let currentTime  = engine.current?.getTime()
    // console.log(`video.duration`,video.duration-5);
    // if(engine.current?.getTime()===video?.duration-5){
    //   timelineState.current.play({ autoEnd: true });
    // }

    engine.listener.on("ended", ({ engine }) => {
      let video = document.getElementById("myVideo");
      timelineState?.current?.setTime(0);
      video.pause();
      video.currentTime = time;
      // console.log(`video ended`);
    });

    return () => {
      if (!engine) return;
      engine.pause();
      engine.listener.offAll();
      // PopupControl.destroy();
    };
  }, []);

  useEffect(() => {
    var player = document.getElementById("myVideo");
    // console.log("Before: " + player.volume);
    if(player){
      player.volume = videoVolume / 100;
    }
  }, [videoVolume]);

  const updateInteractive = (item, updates) => {
    // console.log(updates);
    updateInteractivity({
      resourceId: match.params.id,
      interactivityItem: item,
      data: updates,
    });
  };

  // const SetVolume = (val) => {
  //   var player = document.getElementById("myVideo");
  //   console.log("Before: " + player.volume);
  //   player.volume = val / 100;
  //   console.log("After: " + player.volume);
  // };

  const handlePlayOrPause = () => {
    let video = document.getElementById("myVideo");
    // video.currentTime = minutes * 60 + seconds;

    if (!timelineState.current) return;
    if (timelineState.current.isPlaying) {
      timelineState.current.pause();
      video.pause();
    } else {
      timelineState.current.play({ autoEnd: true });
      video.play();
    }
  };

  const playFromStart = () => {
    let video = document.getElementById("myVideo");
    timelineState.current.setTime(0);
    if (timelineState.current.getTime() === 0) {
      timelineState.current.play({ autoEnd: true });
      video.play();
    }
  };

  const muteVideo = () => {
    let video = document.getElementById("myVideo");
    video.muted = !video.muted;
  };

  // 时间展示
  const timeRender = (time) => {
    const float = (parseInt((time % 1) * 100 + "") + "").padStart(2, "0");
    const min = (parseInt(time / 60 + "") + "").padStart(2, "0");
    const second = (parseInt((time % 60) + "") + "").padStart(2, "0");
    return <>{`${min}:${second}:${float.replace("0.", "")}`}</>;
  };

  const skipAd = () => {
    const currentTIme = timelineState.current.getTime();

    const skipTime = 5;

    timelineState.current.setTime(currentTIme + skipTime);
  };

  const activeResource = resourcesData[match?.params?.id] || null;

  return (
    <>
      <div className={`c-app ${darkMode ? "c-dark-theme" : ""}`}>
        <CContainer fluid>
              <CButton className="btn-danger mt-3 pt-3 d-flex justify-content-center align-items-center" onClick={() =>resourcesData[match?.params?.id]?.type==="film"? history.push(`/resources`):history.push(`/resources/edit/${match.params.id}`)}>
                <h6>{"< Back to Resources"}</h6>
              </CButton>
          <CRow className="flex-fill">
            <CCol md="8" className="inter-col d-flex flex-column">
              <h1 className="font-weight-bold">RESOURCE NAME</h1>
              <h5 className="inter-title">INTERACTIVITY MANAGER</h5>
              <div
                className="text-center"
                style={{
                  height: "1000px",
                  background: "grey",
                  border: "2px solid #0f0f0f",
                  flexdirection: "coloumn",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <CCard
                  className="container justify-content-center align-items-center"
                  style={{
                    height: "50%",
                    width: "100%", // To make it stretch across the video
                    position: "relative",
                    background: "black",
                    border: "2px solid #000000",
                    // display: "flex", // Fixed the typo: "flexdirection" to "flexDirection"
                    // flexDirection: "column", // Fixed the typo: "flexdirection" to "flexDirection"
                    position: "relative",
                    // top: 0, // Position it at the top of the parent div
                    // left: 0, // Position it at the left of the parent div
                    zIndex: 1, // Ensure it's displayed above the video
                  }}
                >
                  {/* <div>NO ACTIVE OVERLAY</div> */}
                  
                  {!!videoUrl && (
                    <video
                    id="myVideo"
                    controls
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "rgba(101,5,2,0.4)",
                      // border:"1px solid red",
                      position: "relative", // Position video relative to the parent
                    }}
                    >
                      <source 
                      src={resourcesData[match?.params?.id]?.url} 
                      type="video/mp4" 
                      />
                      Your Browser does not support HTML video.
                    </video>
                  )}
                  <div
                    id="player-ground-1"
                    style={{
                      display: "block",
                      // flexDirection: "column", // Fixed the typo: "flexdirection" to "flexDirection"
                      backgroundImage:"linear-gradient(to bottom left, rgba(0, 0, 0,0), rgba(0, 0, 0,0))",
                      position: "absolute",
                      bottom: "14%", // Position it at the top of the parent div
                      left: "6%", // Position it at the left of the parent div
                      zIndex: 1, // Ensure it's displayed above the video
                    }}
                    className="btn-transparent"
                  ></div>
                </CCard>
                <div className="time d-flex justify-content-between m-3 bg-grey">
                  <CButton
                    className={"btn-dark btn-outline-white"}
                    onClick={handlePlayOrPause}
                  >
                    Play
                  </CButton>
                  <CButton
                    className={"btn-dark btn-outline-white"}
                    onClick={playFromStart}
                  >
                    Play from Start
                  </CButton>
                  {timeRender(time)}
                  <input
                    id="vol-control"
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    value={videoVolume}
                    onInput={(e) => setVideoVolume(e.target.value)}
                    // onChange={(e)=>SetVolume(e.target.value)}
                  ></input>
                  <CButton
                    className={"btn-dark btn-outline-white"}
                    onClick={muteVideo}
                  >
                    Mute
                  </CButton>
                </div>
                <div
                  style={{
                    height: "35%",
                    background: "rgba(0,0,0,0.6)",
                    border: "2px solid #0f0f0f",
                    display: "flex",
                  }}
                >
                  <div
                    ref={domRef}
                    style={{
                      width: "105px",
                      marginTop: "42px",
                      height: "258px",
                      flex: "0 1auto",
                      overflow: "overlay",
                      // padding: "0 10px",
                    }}
                    onScroll={(e) => {
                      const target = e.target;
                      timelineState.current.setScrollTop(target.scrollTop);
                    }}
                    className={"timeline-list"}
                  >
                    {mockDataState?.map((item) => {
                      return (
                        <div
                          style={{
                            height: "32px",
                            // padding: "2px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "between",
                            alignItems: "center",
                          }}
                          className="btn btn-outline-light rounded-0 text-white bg-dark timeline-list-item"
                          // key={item.id}
                          key={item.actions[0].actionId}
                        >
                          <div className="text">
                            {`${item.id}. ${item.actions[0].type} `}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <Timeline
                    // engine={engine.on("play", ({ engine }) => {
                    //   engine.play({ autoEnd: true });
                    // })}

                    autoReRender={true}
                    ref={timelineState}
                    editorData={mockDataState}
                    effects={mockEffectState}
                    autoScroll={autoScroll}
                    dragLine={dragLine}
                    gridSnap={gridSnip}
                    scale={scale}
                    scaleWidth={scaleWidth}
                    scaleSplitCount={scaleSplitCount}
                    startLeft={15}
                    // getScaleRender={(scale) => <CustomScale scale={scale} />}
                    style={{
                      height: "100%",
                      width: "100%",
                      // background: "rgba(0,5,2,0.6)",
                      border: "2px solid #0f0f0f",
                      display: "flex",
                      flexdirection: "coloumn",
                    }}
                    // onActionResizing={({ action, dir }) => {
                    //   if (action.id === "action10" && dir !== "left")
                    //     return false;
                    // }}
                    getActionRender={(action, row) => {
                      return (
                        <Draggable
                          axis="y"
                          grid={[32, 32]}
                          bounds={{top: -100, left: -action.start, right: 100, bottom: 100}}
                          onDrag={(e, ui) => handleDrag(row)(e, ui)}
                          {...dragHandlers(row)}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              backgroundColor: "rgb(25, 148, 70,0.5)",
                              backgroundPosition: "bottom",
                              backgroundRepeat: "repeat-x",
                              paddingTop: "6.5px",
                              paddingBottom: "6.5px",
                              height: "100%",
                              width: "100%",
                              overflow: "hidden",
                            }}
                          >
                            {action.type}
                            y: {deltaPositions[row.id]?.y.toFixed(0)}
                          </div>
                        </Draggable>
                      );
                    }}
                    onScroll={({ scrollTop }) => {
                      domRef.current.scrollTop = scrollTop;
                    }}
                    // onClickAction={(e, { action ,row, time }) => {
                    //   if (action) {
                    //     action.selected = true;
                    //   }
                    //   console.log(action.start,`time=`,time)
                    // }}
                    onChange={(MockDataState) => {
                      setMockDataState(MockDataState);
                    }}
                    onDoubleClickRow={(e, { row, time }) => {
                      timelineState.current.setTime(time);
                    }}
                    //onDoubleClickRow create new action
                    // onDoubleClickRow={(e, { row, time }) => {
                    //   setMockDataState((pre) => {
                    //     const rowIndex = pre.findIndex(
                    //       (item) => item.id === row.id
                    //     );
                    //     const newAction = {
                    //       id: `action${idRef.current++}`,
                    //       start: time,
                    //       end: time + 0.5,
                    //       effectId: "effect0",
                    //     };
                    //     pre[rowIndex] = {
                    //       ...row,
                    //       actions: row.actions.concat(newAction),
                    //     };
                    //     return [...pre];
                    //   });
                    // }}
                  />
                </div>
                <CCard>
                  <CCardFooter style={{ border: "0px solid black" }}>
                    <CRow>
                      <CCol size="sm">
                        <CFormGroup
                          className={
                            "d-flex justify-content-center align-items-center"
                          }
                        >
                          <CLabel className={"ml-3 mt-2 mr-1"}>
                            AutoScroll
                          </CLabel>
                          <CInput
                            className={""}
                            type="checkbox"
                            checked={autoScroll}
                            onChange={(e) => setAutoScroll(!autoScroll)}
                            style={{
                              height: "17px",
                              width: "17px",
                              background: "red",
                            }}
                          />
                          <CLabel className={"ml-3 mt-2 mr-1"}>
                            Drag Line
                          </CLabel>
                          <CInput
                            className={""}
                            type="checkbox"
                            checked={dragLine}
                            onChange={(e) => setDragLine(!dragLine)}
                            style={{
                              height: "17px",
                              width: "17px",
                              background: "red",
                            }}
                          />
                          <CLabel className={"ml-3 mt-2 mr-1"}>
                            Grid Snip
                          </CLabel>
                          <CInput
                            className={""}
                            type="checkbox"
                            checked={gridSnip}
                            onChange={(e) => setGridSnip(!gridSnip)}
                            style={{
                              height: "17px",
                              width: "17px",
                              background: "red",
                            }}
                          />
                          <CLabel className={"ml-3 mt-2 mr-1"}>
                            Scale Split Count
                          </CLabel>
                          <input
                            className={"form-control"}
                            type="text"
                            // placeholder="20"
                            value={scaleSplitCount}
                            onChange={(e) => {
                              let value = e.target.value;
                              setScaleSplitCount(value);
                            }}
                            style={{
                              height: "42px",
                              width: "42px",
                              background: "#282933",
                              color: "white",
                            }}
                          />
                          <CLabel className={"ml-3 mt-2 mr-1"}>
                            Scale Value
                          </CLabel>
                          <input
                            className={"form-control"}
                            type="text"
                            // placeholder="20"
                            value={scale}
                            onChange={(e) => {
                              let value = e.target.value;
                              setScale(value);
                            }}
                            style={{
                              height: "42px",
                              width: "42px",
                              background: "#282933",
                              color: "white",
                            }}
                          />
                          <input
                            id="scale-control"
                            type="range"
                            min="1"
                            max="15"
                            step="1"
                            value={scale}
                            onInput={(e) => setScale(e.target.value)}
                            // onChange={(e)=>SetVolume(e.target.value)}
                          ></input>
                          {/* <CButton>Set the Scale Settings</CButton> */}
                        </CFormGroup>
                      </CCol>
                    </CRow>
                  </CCardFooter>
                </CCard>
              </div>
              <div className="d-flex justify-content-center align-items-center inter-controller"></div>
            </CCol>
            <CCol md="4" className="inter-col">
              <div className="d-flex justify-content-between mb-4">
                <div>
                  <label className="mr-2">Sort by: </label>
                  <CSelect
                    color="danger"
                    className="inter-filter"
                    custom
                    name="filter"
                    id="filter"
                    // value={this.state.difficultyCode}
                    onChange={(e) => {}}
                  >
                    {[{ name: "By Date", value: "date" }].map((item, index) => {
                      return (
                        <option key={`date-${index}`} value={`${item.value}`}>
                          {item.name}
                        </option>
                      );
                    })}
                  </CSelect>
                </div>
                <CButton
                  size="lg"
                  color="primary"
                  onClick={
                    () =>
                      // history.push(`/resources/interactivity/${match.params.id}/new`)
                      history.push(
                        `/resources/vodInteractivity/${match.params.id}/new`
                      )
                    // setModalAlert({
                    //   title: "Create Interactivity",
                    //   message: "Create or Edit interactivity",
                    //   inputs:[{type:"text",name:""}],
                    //   color: "success",
                    //   buttons: [
                    //     { title: "OK", onClick: () => setModalAlert(null) },
                    //   ],
                    // })
                  }
                >
                  + Create Interactivity
                </CButton>
              </div>
              <div className="inter-col-content">
                {interactives?.[match.params.id]?.map((item, index) => {
                  return (
                    <CCard
                      key={`e-${index}`}
                      color={
                        item.actions[0].status === "active" ? "primary" : ""
                      }
                      borderColor="light"
                    >
                      <CCardHeader className="d-flex justify-content-between p-2">
                        <div>
                          <h5 className="mb-0 inter-type">
                            {item.actions[0].type}
                          </h5>
                        </div>
                        <CSwitch
                          className=""
                          color="success"
                          checked={item?.actions[0]?.status === "active"}
                          onChange={() => {
                            // updateInteractive(item?.actions[0]?.interactiveId, {
                            //   status:
                            //     item?.actions[0]?.status === "active"
                            //       ? "inactive"
                            //       : "active",
                            // });
                            updateInteractive(item, {
                              status:
                                item?.actions[0]?.status === "active"
                                  ? "inactive"
                                  : "active",
                            });
                          }}
                        />
                      </CCardHeader>
                      <CCardBody className="p-2 d-flex justify-content-between align-items-end">
                        <div>
                          <h3 className="mb-1">{item.actions[0].title}</h3>
                          <CBadge color={"success"} className="mr-2">
                            Start:{item.actions[0].start}
                          </CBadge>
                          <CBadge color={"danger"} className="mr-2">
                            End:{item.actions[0].end}
                          </CBadge>
                          <br />
                          <CBadge color={"success"} className="mr-2">
                            {item.actions[0].type === "quiz"
                              ? `${item.actions[0].questions?.length} questions`
                              : `${item.actions[0].choices?.length} choices`}
                          </CBadge>
                          <br />
                          <CBadge color={"light"} className="mr-2">
                            Created:{" "}
                            {item?.actions[0].createdAt
                              ? moment(
                                  item.actions[0].createdAt.toDate()
                                ).format("lll")
                              : ""}
                          </CBadge>
                          <br />
                          <CBadge color={"light"} className="mr-2">
                            Updated:{" "}
                            {item?.actions[0].updatedAt
                              ? moment(
                                  item.actions[0].updatedAt.toDate()
                                ).format("lll")
                              : ""}
                          </CBadge>
                        </div>
                        <div>
                          <CButton
                            size={"sm"}
                            className="ml-2"
                            color="info"
                            onClick={(e) => {
                              history.push(
                                `/resources/vodInteractivity/${match.params.id}/edit/${item.actions[0].interactiveId}`
                              );
                            }}
                          >
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton
                            size={"sm"}
                            className="ml-2"
                            color="danger"
                            onClick={(e) => {
                              // removeQuestion(qItem)

                              e.stopPropagation();
                              setModalAlert({
                                title: "Delete Interactivity",
                                message: `Are you sure you want to delete this Interactivity?`,
                                color: "danger",
                                buttons: [
                                  {
                                    title: "Delete",
                                    color: "danger",
                                    onClick: () => {
                                      // setModalAlert(null);
                                      deleteInteractivity({
                                        resourceId: match.params.id,
                                        interactiveItem:
                                          item
                                      });
                                      setTimeout(()=>{
                                        setModalAlert(null)
                                      },500)
                                    },
                                  },
                                  {
                                    title: "Cancel",
                                    color: "secondary",
                                    onClick: () => setModalAlert(null),
                                  },
                                ],
                              });
                            }}
                          >
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </CCardBody>
                    </CCard>
                  );
                })}
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <CModal
        show={modalAlert}
        onClose={() => {
          setModalAlert(null);
          history.push("/");
        }}
        color={modalAlert?.color || "secondary"}
      >
        <CModalHeader closeButton>
          <CModalTitle>{modalAlert?.title || ""}</CModalTitle>
        </CModalHeader>
        <CModalBody>{modalAlert?.message || ""}</CModalBody>
        <CModalFooter>
          {modalAlert?.buttons?.map((item) => {
            return (
              <CButton color={item.color || "secondary"} onClick={item.onClick}>
                {item.title}
              </CButton>
            );
          })}
        </CModalFooter>
      </CModal>
    </>
  );
};

export default VodInteractivity;
