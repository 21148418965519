import { create } from "zustand";
import firebase from "firebase";
import { channelsType, channelGroupsType } from "./types";

const timestamp = firebase.firestore.FieldValue.serverTimestamp();

const useChannelsStore = create((set, get) => ({
  channels: [],
  channel: {},
  channelsData: [],
  currentChannel: [],
  channelsStatus: "",
  channelsError: "",
  //channel Groups
  channelGroups: [],
  channelGroupsData: [],
  channelGroupsStatus: "",
  channelGroupsError: "",
  //groupChannels
  groupChannels: [],

  getChannels: () => {
    set({ channelsStatus: channelsType.GETCHANNELS_LOADING });

    firebase
      .firestore()
      .collection("channels")
      .orderBy("createdAt", "desc")
      .get()
      .then((snaps) => {
        let results = snaps.docs.map((doc) => doc.data());
        console.log(`getChannels success`);
        let newData = [];

        results.forEach((item) => {
          newData[item.channelId] = item;
        });

        set({
          channels: results,
          channelsData: {
            ...get().channelsData,
            ...newData,
          },
          channelsStatus: channelsType.GETCHANNELS_SUCCESS,
        });
      })
      .catch((error) => {
        set({
          channelsStatus: channelsType.GETCHANNELS_FAIL,
          channelsError: error.message,
        });
      });
  },
  getChannel: (channelId) => {
    set({ channelsStatus: channelsType.GETCHANNEL_LOADING });

    firebase
      .firestore()
      .collection("channels")
      .doc(channelId)
      .get()
      .then((snap) => {
        let result = snap.data();
        console.log(`getChannel success`);
        set({
          channel: result,
          channelsStatus: channelsType.GETCHANNEL_SUCCESS,
        });
      })
      .catch((error) => {
        set({
          channelsStatus: channelsType.GETCHANNEL_FAIL,
          channelsError: error.message,
        });
      });
  },
  // createChannelGroup: (updates) => {
  //   const uid = firebase.auth().currentUser?.uid;

  //   set({ channelGroupsStatus: channelGroupsType.CREATECHANNELGROUP_LOADING });

  //   let ref = firebase.firestore().collection("channelGroups").doc();
  //   ref.set({
  //     organizationId: ref.id,
  //     ...updates,
  //     authorId: uid,
  //     editors: [uid],
  //     createdAt: timestamp,
  //     updatedAt: timestamp,
  //   }).then(()=>{
  //     console.log(`create channel group success`);
  //     set({ channelGroupsStatus: channelGroupsType.CREATECHANNELGROUP_SUCCESS });
  //   }).catch((error)=>{
  //     console.log(`create channel error`,error);
  //     console.log(`create channel error.message`,error.message);
  //     set({ channelGroupsStatus: channelGroupsType.CREATECHANNELGROUP_FAIL });
  //   })
  // },
  getChannelGroups: () => {
    set({ channelGroupsStatus: channelGroupsType.CREATECHANNELGROUP_SUCCESS });

    firebase
      .firestore()
      .collection("channelGroups")
      .orderBy("createdAt", "desc")
      .get()
      .then((snaps) => {
        let results = snaps?.docs?.map((doc) => doc.data());
        console.log(`getChannelGroups success`);

        let newData = [];

        results.forEach((item) => {
          newData[item.channelGroupId] = item;
        });

        set({
          channelGroups: results,
          channelGroupsData: {
            ...get().channelGroupsData,
            ...newData,
          },
          channelGroupsStatus: channelGroupsType.GETCHANNELGROUPS_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(`getChannelGroups error`, error.message);
        set({
          channelGroupsStatus: channelGroupsType.GETCHANNELGROUPS_FAIL,
          channelGroupsError: error.message,
        });
      });
  },
  deleteChannelGroup: (channelGroupId) => {
    set({ channelGroupsStatus: channelGroupsType.DELETECHANNELGROUP_LOADING });

    firebase
      .firestore()
      .collection("channelGroups")
      .doc(channelGroupId)
      .delete()
      .then(() => {
        console.log("Delete channelGroup success");
        set({
          channelGroupsStatus: channelGroupsType.DELETECHANNELGROUP_SUCCESS,
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log("Delete channelGroup error:", error.message);
        set({
          channelGroupsStatus: channelGroupsType.DELETECHANNELGROUP_FAIL,
          channelGroupsError: error.message,
        });
      });
  },
  updateChannelGroup: (channelGroupId, updates) => {
    set({ channelGroupsStatus: channelGroupsType.UPDATECHANNELGROUP_LOADING });

    firebase
      .firestore()
      .collection("channelGroups")
      .doc(channelGroupId)
      .update({
        ...updates,
        updatedAt: timestamp,
      })
      .then(() => {
        console.log("Update channelGroup success");
        set({
          channelGroupsStatus: channelGroupsType.UPDATECHANNELGROUP_SUCCESS,
        });
      })
      .catch((error) => {
        console.log("Update channelGroup error:", error.message);
        set({
          channelGroupsStatus: channelGroupsType.UPDATECHANNELGROUP_FAIL,
          channelGroupsError: error.message,
        });
      });
  },
  //funciton to fetch channels under a group from firebase
  getGroupChannels: (channelGroupId) => {
    set({ channelsStatus: channelsType.GETCHANNELGROUPSID_LOADING });

    firebase
      .firestore()
      .collection("channelGroups")
      .doc(channelGroupId)
      .get()
      .then((result) => {
        let channelGroup = result.data();
        let fetchedGroupChannelsId = channelGroup.channels;

        console.log(`get groupChannelsId success`);
        set({
          channelGroupsStatus: channelGroupsType.GETCHANNELGROUPSID_SUCCESS,
        });

        set({ channelGroupsStatus: channelsType.GETCHANNELGROUPS_LOADING });
        firebase
          .firestore()
          .collection("channels")
          .where("channelId", "in", fetchedGroupChannelsId)
          .orderBy("createdAt", "asc")
          .get()
          .then((snaps) => {
            let fetchedGroupChannels = snaps.docs.map((doc) => doc.data());
            console.log(`get group channels success`);

            set({
              groupChannels: fetchedGroupChannels,
              channelGroupsStatus: channelsType.GETCHANNELGROUPS_SUCCESS,
            });
          })
          .catch((error) => {
            console.log(`get group channels error`, error.message);
            set({
              channelGroupsStatus: channelGroupsType.GETCHANNELGROUPS_FAIL,
              channelGroupsError: error.message,
            });
          });
      })
      .catch((error) => {
        set({
          channelGroupsStatus: channelGroupsType.GETCHANNELGROUPSID_FAIL,
          channelGroupsError: error.message,
        });
      });
  },
  selectedChannelsOperations: async (operationType,updates, channelIdArray) => {
    const channels = await firebase
      .firestore()
      .collection("channels")
      .where("channelId", "in", channelIdArray)
      .get();
    const batch = firebase.firestore().batch();

    if (operationType === "delete") {
      set({
        channelsStatus: channelsType.DELETESELECTEDCHANNELS_LOADING,
      });
      channels.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch
        .commit()
        .then(() => {
          console.log("delete selected channels success");
          set({
            channelsStatus: channelsType.DELETESELECTEDCHANNELS_SUCCESS,
          });
          // window.location.reload();
        })
        .catch((error) => {
          console.log("delete selected channels error", error.message);
          set({
            channelsStatus: channelsType.DELETESELECTEDCHANNELS_FAIL,
          });
        });
    } else if (operationType === "changeStatus") {
      set({
        channelsStatus: channelsType.UPDATESELECTEDCHANNELS_LOADING,
      });

      channels.forEach((doc) => {
        batch.update(doc.ref,{status:updates});
      });

      await batch
        .commit()
        .then(() => {
          console.log("updated selected channels success");
          set({
            channelsStatus: channelsType.UPDATESELECTEDCHANNELS_SUCCESS,
          });
          // window.location.reload();
        })
        .catch((error) => {
          console.log("updating selected channels error", error.message);
          set({
            channelsStatus: channelsType.UPDATESELECTEDCHANNELS_FAIL,
          });
        });
    }
  },
}));

export default useChannelsStore;
